<template>
  <div class="changePassword">
    <div class="personal-title">{{ t('641') }}</div>
    <div>
      <div>{{ t('852') }}：</div>
      <el-input
        show-password
        v-model.trim="data.oldPassword"
        @blur="checkOldpass"
        @input="checkOldpass"
      ></el-input>
      <transition name="el-zoom-in-top">
        <span class="tips" v-show="oldNull">{{ t('853') }}！</span>
      </transition>
    </div>
    <div>
      <div>{{ t('854') }}：</div>
      <el-input
        v-model.trim="data.newPassword"
        show-password
        @input="checkNewpass"
        @blur="checkNewpass"
      ></el-input>
      <transition name="el-zoom-in-top">
        <span class="tips" v-show="passwordNull">{{ t('853') }}！</span>
      </transition>
    </div>
    <div>
      <div>{{ t('855') }}：</div>
      <el-input
        v-model.trim="data.confirmPassword"
        show-password
        @blur="checkConfimpass"
        @input="checkConfimpass"
      ></el-input>
      <transition name="el-zoom-in-top">
        <span class="tips" v-show="confirmNull">{{ t('853') }}！</span>
      </transition>
    </div>
    <el-button type="primary" @click="updatePassword">{{ t('856') }}</el-button>
  </div>
</template>

<script >
import { t } from '../../../languages';
import {
  defineComponent,
  inject,
  onMounted,
  reactive,
  watch,
  ref,
} from "vue-demi";
import userService from "@/network/user";
import { ElMessage } from "element-plus/lib/components";
import { store } from "@/store";
import JSEncrypt from "jsencrypt";
import { router } from "@/router";

export default defineComponent({
  setup() {
    let data = reactive({
      oldPassword: "", //旧密码
      newPassword: "", //新密码
      confirmPassword: "", //确认密码
    });

    onMounted(() => {
      const userInfo =
        store.state.user.userInfo || localStorage.getItem("userInfo");
      data.id = userInfo.id;
    });

    let publicKey = "";
    /* 获取公钥 */
    async function getPublicKey() {
      const res = await userService.getPublicKey();
      if (res.resultStatus) {
        publicKey = res.resultData;
      }
    }

    /* 修改密码 */
    async function updatePassword() {
      if (
        data.newPassword.trim() === "" ||
        data.oldPassword.trim() === "" ||
        data.confirmPassword.trim() === ""
      ) {
        checkConfimpass();
        checkNewpass();
        checkOldpass();
        return;
      }
      if (data.newPassword !== data.confirmPassword) {
        ElMessage.error(t("857"));
        return;
      }
      if (data.newPassword === data.oldPassword) {
        ElMessage.error(t("858"));
        return;
      }
      await getPublicKey();
      const encryptor = new JSEncrypt();
      encryptor.setPublicKey(publicKey); // 设置公钥
      const encryptionNew = encryptor.encrypt(data.newPassword); // 对需要加密的数据进行加密
      const encryptionOld = encryptor.encrypt(data.oldPassword); // 对需要加密的数据进行加密
      let params = {
        memberId: data.id,
        newPassword: encryptionNew,
        oldPassword: encryptionOld,
      };
      userService.changePassword(params).then((res) => {
        res.resultStatus && ElMessage.success(t("859"));
        res.resultStatus && loginOut();
      });
    }
    /* 退出登录 */
    function loginOut() {
      userService.loginOut().then((res) => {
        if (res.resultStatus) {
          store.commit("removeUserInfo");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("menusId");
          localStorage.removeItem("token");
          router.replace("/");
          ElMessage.success(t("643"));
        }
      });
    }

    //input空值提示语
    let oldNull = ref(false);
    function checkOldpass() {
      data.oldPassword && (oldNull.value = false);
      data.oldPassword || (oldNull.value = true);
    }
    let passwordNull = ref(false);
    function checkNewpass() {
      data.newPassword && (passwordNull.value = false);
      data.newPassword || (passwordNull.value = true);
    }
    let confirmNull = ref(false);
    function checkConfimpass() {
      data.confirmPassword && (confirmNull.value = false);
      data.confirmPassword || (confirmNull.value = true);
    }

    return {
      data,
      updatePassword,
      oldNull,
      confirmNull,
      passwordNull,
      checkOldpass,
      checkConfimpass,
      checkNewpass,
    };
  },
});
</script>

<style lang="less" scoped>
.changePassword {
  margin: 0 2.875rem 0 1.625rem;
  /deep/.el-input__wrapper {
    background: rgba(0, 28, 49, 0.7);
    border: 1px solid #00a1a8;
    height: 2.875rem;
    margin-top: 1rem;
    box-shadow: 0 0 0 0;
  }
  & > div:nth-child(n + 2) {
    text-align: left;
    padding-top: 1.1875rem;
    height: 8.375rem;
    font-size: 1.125rem;
  }
  .tips {
    font-size: 0.75rem;
    color: #f56c6c;
  }
  .el-button {
    margin-top: 2.375rem;
    width: 8.4375rem;
    height: 2.875rem;
    background: rgba(0, 112, 195, 0.7) !important;
    border: 1px solid #00a1a8;
    float: right;
  }
  /deep/.el-input__inner {
    color: #ffffff;
    font-weight: 400;
    font-family: Source Han Sans CN;
    font-size: 1.125rem;
  }
}
</style>